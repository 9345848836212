





































































import { Component, Vue, Watch } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import DataTable from '@/app/ui/components/DataTable/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import controller, { ParamsGetAll } from '@/app/ui/controllers/ManageCustomerController'
import { Utils } from '@/app/infrastructures/misc'
import { ManageCustomer } from '@/domain/entities/ManageCustomer'
import { Dictionary } from 'vue-router/types/router'

@Component({
  components: {
    TextInput,
    DropdownSelect,
    DataTable,
    PaginationNav
  },
})
export default class ManageCustomerlist extends Vue {
  controller = controller
  statusOptions = [
    { label: 'Show All', value: '' },
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Banned', value: 'BANNED' },
    { label: 'Non Login', value: 'NONLOGIN' },
    { label: 'Deleted', value: 'DELETED' },
    { label: 'Suspended', value: 'SUSPENDED' },
  ]
  sortByOptions = [
    { label: 'A - Z', value: 'ASC' },
    { label: 'Z - A', value: 'DESC' },
  ]
  // Table
  headers = [
    'ID',
    'Full Name',
    'Phone Number',
    'E-mail',
    'Membership',
    'Status',
  ]
  customerList = Array<string[] | number[]>([])
  parameters = {
    query: '',
    customerStatus: this.statusOptions[0],
    sortBy: this.sortByOptions[0],
    page: 1,
    perPage: 25
  }

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = this.$route.query as Record<string, never>
      this.parameters = {
        query: queries.keyword || '',
        customerStatus:
          this.statusOptions.find((item) => item.value === queries.customerStatus) ||
          this.statusOptions[0],
        sortBy:
          this.sortByOptions.find((item) => item.value === queries.sortBy) ||
          this.sortByOptions[0],
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage: 25,
      }
    }
    this.fetchCustomerList()
  }

  get params(): ParamsGetAll {
    return {
      ...this.parameters,
      sortBy: this.parameters.sortBy.value,
      customerStatus: this.parameters.customerStatus.value,
      query: encodeURIComponent(this.parameters.query),
    }
  }

  private fetchCustomerList(reset?: boolean): void {
    if (reset) this.parameters.page = 1
    controller.getAll(this.params)
  }

  private onSearchCustomer = Utils.debounce((): void => {
    this.fetchCustomerList(true)
  }, 500)

  private getStatusCustomer = (status: string): string => {
    if (status.toLowerCase() === 'banned') {
      return 'Banned'
    } else if (status.toLowerCase() === 'nonlogin') {
      return 'Non Login'
    } else {
      return Utils.toCapitalize(status)
    }
  }

  @Watch('params')
  onParamsChanged(val: Dictionary<string | (string | null)[] | null | undefined> | undefined): void {
    this.$router.replace({
      query: { ...val },
    })
  }

  @Watch('controller.customerList')
  setCustomerList(data: ManageCustomer[]): void {
    const result = data.map(customer => {
      return [
        customer.id,
        customer.fullname,
        customer.phoneNumber,
        customer.email,
        customer.membershipLevel,
        this.getStatusCustomer(<string>customer.status),
      ]
    })
    this.customerList = <Array<string[] | number[]>>result
  }
}
